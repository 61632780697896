if (module.hot) {
  module.hot.accept();
}

import $ from 'jquery';
window.jQuery = $;
window.$ = $;
import 'slick-carousel';
var Masonry = require('masonry-layout');

var msnry;

let firefoxAgent = navigator.userAgent.indexOf("Firefox") > -1;
if(firefoxAgent){
  $('html').addClass('firefox')
}

$(window).on('load', function(){
  msnry = new Masonry( '.masonry-list', {
    itemSelector: '.speakers-item-holder'
  });
  $('.masonry-list-holder').css('opacity', 1);
  if(window.location.href.indexOf("foryour") > -1){
    var node = document.createElement("script");
    var node2 = document.createElement("script");
    node.setAttribute('src', '//nexus.dell.com/dell/emcDev/Bootstrap.js');
    node2.setAttribute('src', '//nexus.dell.com/dell/marketingDev/Bootstrap.js');
    document.querySelector('body').appendChild(node);
    document.querySelector('body').appendChild(node2);
  }

  if(window.location.href.indexOf("delltechnologiespodference.com") > -1){
    var node = document.createElement("script");
    var node2 = document.createElement("script");
    node.setAttribute('src', '//nexus.dell.com/dell/emc/Bootstrap.js');
    node2.setAttribute('src', '//nexus.dell.com/dell/marketing/Bootstrap.js');
    document.querySelector('body').appendChild(node);
    document.querySelector('body').appendChild(node2);
  }
})

$(document).ready(function(){

  var currentSlideID;
  var allSlides;

  $('nav ul li').keypress(function(event) {
    if (event.keyCode === 13) {
        $('nav ul li').click();
    }
  }).click(function(){
    if(this.hasAttribute('scroll-to')){
      var ua = window.navigator.userAgent;
      var msie = ua.indexOf("MSIE ");

      if (msie >= 0){
        window.scroll(0, $('.' + this.getAttribute('scroll-to')).offset().top - 50);
      }else{
        window.scroll({
          top: $('.' + this.getAttribute('scroll-to')).offset().top - 50,
          left: 0,
          behavior: 'smooth'
        });
      }
    }
  })

  $('.featured-carousel').slick({
    slidesToShow: 3,
    centerMode: true,
    centerPadding: '80px',
    initialSlide: 1,
    dots: true,
    //autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          centerPadding: '0px',
          initialSlide: 0,
          autoplay: false,
        }
      }
    ],
  });

  $('.ghf-footer-links-holder').click(function(){
    this.classList.toggle('active');
  })

  $('nav .burger').click(function(){
    $('nav').toggleClass('active');
  })

  allSlides = $('.slick-slide:not(.slick-cloned) .featured-frame');
  console.log(allSlides);

  $('.featured-frame:not(.disabled)').click(function(){
    currentSlideID = parseInt(this.getAttribute('slideID'));
    var overlay = $('.overlay');
    var carouselOverlay = $('.carousel-overlay');
    carouselOverlay.find('h3').html(this.querySelector('h3').innerHTML);
    carouselOverlay.find('h4').html(this.querySelector('h4').innerHTML);
    carouselOverlay.find('p').html(this.querySelector('p').innerHTML);
    carouselOverlay.find('img').attr('src', this.getAttribute('imgLink'));
    $('.holder').addClass('blur');
    $('header').addClass('blur');
    overlay.addClass('show');
    carouselOverlay.addClass('show');
  })

  $('.carousel-overlay .arrow').on('click', overlayArrow);

  $('.how-to').click(function(){
    var overlay = $('.overlay');
    var howToOverlay = $('.how-to-overlay');
    $('.holder').addClass('blur');
    $('header').addClass('blur');
    overlay.addClass('show');
    howToOverlay.addClass('show');
  })

  $('.how-to-overlay .frame-1 li').on('click', overlaySlideOption);
  $('.how-to-overlay .frame-2 .slide-arrow.slide-back').on('click', overlaySlideBack);

  function overlaySlideOption(event){
    var slideForwardID = this.getAttribute('slide-id');
    $('.how-to-overlay .frame-2').find('.'+slideForwardID).addClass('show');
    $('.how-to-overlay .frame-1, .how-to-overlay .frame-2').css('transform', 'translateX(-100%)');
  }

  function overlaySlideBack(event){
    $('.how-to-overlay .frame-2 > div').removeClass('show');
    $('.how-to-overlay .frame-1, .how-to-overlay .frame-2').css('transform', 'translateX(0%)');
  }

  function changeSlide(dir){
    if(dir === "left"){
      currentSlideID--;
      if (currentSlideID < 0){
        currentSlideID = allSlides.length - 1;
      }
    } else {
      currentSlideID++;
      if (currentSlideID >= allSlides.length){
        currentSlideID = 0;
      }
    }
    if(allSlides[currentSlideID].classList.contains('disabled') || allSlides[currentSlideID].classList.contains('skip')){
      changeSlide(dir);
    }
  }

  function overlayArrow(event){
    if(this.classList.contains('arrow_left')){
      changeSlide("left");
    } else {
      changeSlide("right");
    }
    var carouselOverlay = $('.carousel-overlay');
    $('.carousel-overlay .overlay-img, .carousel-overlay .overlay-text, .carousel-overlay .arrow').fadeOut(100, function(){
      $('.carousel-overlay .close').hide();
      carouselOverlay.find('h3').html(allSlides[currentSlideID].querySelector('h3').innerHTML);
      carouselOverlay.find('h4').html(allSlides[currentSlideID].querySelector('h4').innerHTML);
      carouselOverlay.find('p').html(allSlides[currentSlideID].querySelector('p').innerHTML);
      carouselOverlay.find('img').attr('src', allSlides[currentSlideID].getAttribute('imgLink'));
      $('.carousel-overlay .overlay-img, .carousel-overlay .overlay-text, .carousel-overlay .arrow').delay(500).fadeIn(100, function(){
        $('.carousel-overlay .close').show();
        //$('.carousel-overlay .arrow').on('click', overlayArrow);
      });
    })
  };

  $('.overlay .close, .overlay .close hr, .overlay').click(function(event){
    var overlay = $('.overlay');
    var carouselOverlay = $('.carousel-overlay');
    var howToOverlay = $('.how-to-overlay');
    $('header').removeClass('blur');
    $('.holder').removeClass('blur');
    carouselOverlay.find('img').attr('src', "");
    $('.how-to-overlay .frame-2 > div').removeClass('show');
    $('.how-to-overlay .frame-1, .how-to-overlay .frame-2').css('transform', 'translateX(0%)');
    overlay.removeClass('show');
    howToOverlay.removeClass('show');
    carouselOverlay.removeClass('show');
  })

  $('.overlay .inner-overlay').click(function(event){
    event.stopPropagation();
  })

  $('.podcast-carousel').slick({
    slidesToShow: 4,
    slidesToScroll: 4,
    dots: true,
    responsive: [
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ],
  });

  /*$('.featured-carousel').on('beforeChange', function(event, slick, currentSlide, nextSlide){
    if (window.innerWidth > 650){
      let slideOffset = nextSlide-currentSlide;
      let slideToGo = currentSlide + 3;
      if(slideOffset < 0){
        slideToGo = currentSlide - 3;
      }

      if(Math.abs(slideOffset) <3){
        try {
          throw "prevent-slide";
        } catch(e) {
           $('.featured-carousel').slick('slickGoTo', slideToGo);
           throw e;
        }
      }
    }
  });*/

  $('.show-more').click(expandMasonry);
});

var expandMasonry = function(){
  var listHolder = $('.masonry-list-holder');
  var list = $('.masonry-list');
  var maxHeight = parseInt(listHolder.css('max-height'));
  maxHeight += 2000;
  listHolder.css('max-height', maxHeight+"px");
  var realHeight = parseInt(list.css('height'));
  if(maxHeight > realHeight + 80){
    $('.show-more').fadeOut();
    $('.masonry-gradient').fadeOut();
  }
}

window.onerror = function(msg, url, line, col, error) {
    if (error === "prevent-slide") {
        return true;  //  silent the error, and keep functioning as normal
    }
}
